import { createSlice } from '@reduxjs/toolkit';
import {
  IAnalysisDocumentPreview,
  IAnalysisDocumentDetails,
  IDocumentStatus,
  IDocumentRules,
  IAnalysisRule,
  IAnalysisCategoryV2
} from '../../../shared/models/Analysis-Document';
import { IMortgage } from '../../../shared/models/Mortgage';
import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizedLQAValidation } from '../quality/types';

export interface IAnalysisDocumentsState {
  previewDocuments: Record<IMortgage['internalId'], IAnalysisDocumentPreview[]>;
  documentDetails: Record<IMortgage['internalId'], Record<IAnalysisDocumentDetails['id'], IAnalysisDocumentDetails>>;
  documentStatus: Record<IMortgage['internalId'], IDocumentStatus>;
  analysisChecklistCategories: Record<IMortgage['internalId'], IAnalysisCategoryV2[]>;
  analysisChecklistDocumentsById: Record<
    IMortgage['internalId'],
    Record<IAnalysisDocumentPreview['id'], IDocumentRules>
  >;

  lqaValidations: Record<IMortgage['internalId'], NormalizedLQAValidation>;
}

const initialState: IAnalysisDocumentsState = {
  previewDocuments: {},
  documentDetails: {},
  documentStatus: {},
  analysisChecklistCategories: {},
  analysisChecklistDocumentsById: {},
  lqaValidations: {}
};

export type ISetDocumentDetailsPayload = PayloadAction<{
  data: IAnalysisDocumentDetails;
  mortgageId: IMortgage['internalId'];
  documentId: IAnalysisDocumentDetails['id'];
}>;

export type ISetDocumentStatusPayload = PayloadAction<{
  data: IDocumentStatus[];
}>;

const analysisDocumentsSlice = createSlice({
  initialState,
  name: 'analysisDocuments',
  reducers: {
    setDocumentDetails: (state, action: ISetDocumentDetailsPayload) => {
      const { mortgageId, documentId } = action.payload;

      if (!state.documentDetails[mortgageId]) {
        state.documentDetails[mortgageId] = {};
      }
      state.documentDetails[mortgageId][documentId] = action.payload.data;
    },
    setPreviewDocuments: (
      state,
      action: PayloadAction<{ mortgageId: IMortgage['internalId']; data: IAnalysisDocumentPreview[] }>
    ) => {
      state.previewDocuments[action.payload.mortgageId] = action.payload.data;
    },
    setDocumentStatus: (state, action: PayloadAction<{ data: IDocumentStatus[] }>) => {
      action.payload.data.forEach((documentStatus: IDocumentStatus) => {
        state.documentStatus[documentStatus.mortgageId] = documentStatus;
      });
    },
    // TODO, rename as this does more than set the category
    setDocumentCategory: (
      state,
      action: PayloadAction<{
        data: IAnalysisDocumentDetails;
        mortgageId: IMortgage['internalId'];
        documentId: string;
        category?: string | null;
      }>
    ) => {
      const { mortgageId, documentId, category, data } = action.payload;

      state.documentDetails[mortgageId][documentId] = action.payload.data;

      const index = (state.previewDocuments[mortgageId] || []).findIndex((document) => document.id === documentId);

      if (index === -1) {
        return;
      }

      if (typeof category !== 'undefined') {
        state.previewDocuments[mortgageId][index].category = category;
      }
      state.previewDocuments[mortgageId][index].substate = data.substate;
      state.previewDocuments[mortgageId][index].state = data.state;
    },
    setAnalysisChecklistCategory: (
      state,
      action: PayloadAction<{ mortgageId: IMortgage['internalId']; data: IAnalysisCategoryV2[] }>
    ) => {
      if (!state.analysisChecklistCategories[action.payload.mortgageId]) {
        state.analysisChecklistCategories[action.payload.mortgageId] = [];
      }

      state.analysisChecklistCategories[action.payload.mortgageId] = action.payload.data;
    },
    setAnalysisChecklistRulesForDocuments: (
      state,
      action: PayloadAction<{
        mortgageId: IMortgage['internalId'];
        data: IDocumentRules;
      }>
    ) => {
      const { mortgageId, data } = action.payload;

      if (!data.documentId) return;

      if (!state.analysisChecklistDocumentsById[mortgageId]) {
        state.analysisChecklistDocumentsById[mortgageId] = {};
      }
      state.analysisChecklistDocumentsById[mortgageId][data.documentId] = data;
    },
    setAnalysisChecklistRule: (
      state,
      action: PayloadAction<{
        mortgageId: IMortgage['internalId'];
        documentId: IAnalysisDocumentPreview['id'];
        ruleId: IAnalysisRule['ruleId'];
        data: IAnalysisRule;
      }>
    ) => {
      const { mortgageId, ruleId, data, documentId } = action.payload;

      const document = state.analysisChecklistDocumentsById[mortgageId][documentId];
      const ruleIndex = document.rules.findIndex((rule) => rule.ruleId === ruleId);

      document.rules[ruleIndex] = data;

      state.analysisChecklistDocumentsById[mortgageId][documentId] = document;
    },
    setLQAValidations: (
      state,
      action: PayloadAction<{
        data: NormalizedLQAValidation[];
      }>
    ) => {
      action.payload.data.forEach((validation) => {
        state.lqaValidations[validation.mortgageId] = {
          ...validation,
          // Actual validation results array is set by setLQAValidationResults, triggered by useGetValidationByIdQuery
          // This setter is triggered by useGetValidationsQuery, which only fetches validation metadata, but does not return validation results
          // So overwrite the validation metadata, but not the validation results, which are fetched and returned separately
          validations: state.lqaValidations[validation.mortgageId]?.validations || []
        };
      });
    },
    setLQAValidationResults: (
      state,
      action: PayloadAction<{
        mortgageId: IMortgage['internalId'];
        validations: NormalizedLQAValidation['validations'];
      }>
    ) => {
      const { mortgageId, validations } = action.payload;

      state.lqaValidations[mortgageId].validations = validations;
    }
  }
});

export const {
  setDocumentDetails,
  setPreviewDocuments,
  setDocumentStatus,
  setDocumentCategory,
  setAnalysisChecklistCategory,
  setAnalysisChecklistRulesForDocuments,
  setAnalysisChecklistRule,
  setLQAValidations,
  setLQAValidationResults
} = analysisDocumentsSlice.actions;

export default analysisDocumentsSlice.reducer;
